import { Box, CssBaseline, Grid, Link, Typography } from '@mui/material';
import ImageExtractives from 'assets/extractives-unsplash.jpg';
import ImageFarming from 'assets/farming-unsplash.jpg';
import ImageHelmetsLocker from 'assets/helmets-locker-unsplash.jpg';
import ImageMaritimePort from 'assets/maritime-port-unsplash.jpg';
import ImageLogo from 'assets/original-full.svg';
import ImageTrainSafetyVest from 'assets/train-safety-vest-unsplash.jpg';
import ImageTruckFleet from 'assets/truck-fleet-unsplash.jpg';
import { LanguageSelector } from 'components/Navigation/languageSelector';
import SnackbarAlert from 'components/SnackbarAlert';
import useUIContext, { UIContextProvider } from 'context/UIContext';
import { useLocalStorage } from 'hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { languageOptions } from 'views/Settings/Profile';

const images = [
  ImageExtractives,
  ImageFarming,
  ImageHelmetsLocker,
  ImageMaritimePort,
  ImageTrainSafetyVest,
  ImageTruckFleet,
];

function Copyright(props: any) {
  const { isMobile } = useUIContext();

  return (
    <Typography
      variant='body1'
      color='#AAA'
      align='center'
      sx={{ fontSize: isMobile ? '.7rem' : '1rem' }}
      {...props}>
      {'© '}
      {new Date().getFullYear() + ' '}
      <Link color='inherit' href={process.env.REACT_APP_URL}>
        SnapGIS Technologies
      </Link>
      {'. All Rights Reserved'}
    </Typography>
  );
}

const getDefaultLanguage = () => {
  const language = navigator.language.split('-')[0];
  return languageOptions.find(lang => lang.value === language)
    ? language
    : 'en';
};

const InnerContent = ({ component, flashMessage, bottomComponent }: any) => {
  const [imageIndex] = React.useState<number>(
    Math.floor(Math.random() * images.length)
  );
  const [message, setMessage] = React.useState<any | null>({});
  const { setDirection, direction, isMobile } = useUIContext();
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage(
    'language',
    getDefaultLanguage()
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    setMessage(flashMessage);
  }, [flashMessage]);

  useEffect(() => {
    if (['ar'].includes(selectedLanguage)) {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
    i18n.changeLanguage(selectedLanguage);
    setSelectedLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <>
      <SnackbarAlert
        severity={message?.severity ?? 'success'}
        message={message?.message}
        onClose={(event?: React.SyntheticEvent | Event, reason?: string) =>
          setMessage(null)
        }
      />
      <CssBaseline />
      <Grid
        container
        sx={{
          minHeight: isMobile ? '96vh' : '100vh',
          width: '100vw',
          backgroundColor: '#3F4349',
          direction,
        }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          lg={4}
          xl={3}
          sx={{
            padding: 4,
            paddingBottom: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            boxShadow: isMobile ? 'none' : '0 0 4px 2px rgba(0, 0, 0, 0.25)',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}>
            <Box sx={{ height: '42px', mb: 4 }}>
              <img src={ImageLogo} height={'100%'} />
            </Box>
            {component}
          </Box>
          <Box
            sx={{
              marginTop: 'auto',
              marginBottom: 0,
              textAlign: 'center',
            }}>
            {bottomComponent}
          </Box>
          <LanguageSelector />
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          md={7}
          lg={8}
          xl={9}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${images[imageIndex]})`,
            backgroundSize: 'cover',
            filter:
              'grayscale(60%) sepia(30%) blur(1px) brightness(85%) saturate(200%) opacity(40%) contrast(150%)',
            WebkitFilter:
              'grayscale(60%) sepia(30%) blur(1px) brightness(85%) saturate(200%) opacity(40%) contrast(150%)',
          }}></Grid>
      </Grid>
      <Copyright
        style={{
          position: isMobile ? 'static' : 'absolute',
          bottom: isMobile ? 15 : 32,
          [direction == 'rtl' ? 'left' : 'right']: 64,
          fontWeight: '400',
        }}
      />
    </>
  );
};

const AuthWrappedComponent = ({
  component,
  flashMessage,
  bottomComponent,
}: any) => {
  const { direction } = useUIContext();

  return (
    <>
      <UIContextProvider
        defaultState={{
          rightSideBarAlwaysOpen: false,
          direction: direction ?? 'ltr',
          isMobile: window.innerWidth <= 768,
        }}>
        <InnerContent
          component={component}
          flashMessage={flashMessage}
          bottomComponent={bottomComponent}
        />
      </UIContextProvider>
    </>
  );
};

export default AuthWrappedComponent;
