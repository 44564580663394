import { AddCircleOutline, Delete, Edit, GroupAdd } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useCurrentUser, useDeleteGroup, useGroups, useMoveGroup } from 'hooks';
import { t } from 'i18next';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import {
  // eslint-disable-next-line import/named
  DraggingPosition,
  StaticTreeDataProvider,
  Tree,
  // eslint-disable-next-line import/named
  TreeItem,
  UncontrolledTreeEnvironment,
} from 'react-complex-tree';
import 'react-complex-tree/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './style.scss';

const useStyles: any = makeStyles(() =>
  createStyles({
    rotateIcon: {
      transition: 'all .2s ease',
      transform: 'rotate(-90deg)',
    },
    downwardIcon: {
      transform: 'rotate(0deg)',
    },
  })
);

const renderDeleteButton = (
  item: any,
  isDeleting: any,
  handleDeleteGroup: any,
  t: any
) => {
  return (
    item.parentId &&
    (item.hasChildren ? (
      <Tooltip
        title={t('Group must be empty and cannot contain any subgroups')}>
        <IconButton size='small'>
          <Delete color={'disabled'} />
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        disabled={!!isDeleting}
        size='small'
        onClick={e => {
          e.stopPropagation();
          handleDeleteGroup(item);
        }}>
        <Delete />
      </IconButton>
    ))
  );
};

const renderEditButton = (item: any, isDeleting: any, handleEditGroup: any) => {
  return (
    item.parentId && (
      <IconButton
        disabled={!!isDeleting}
        size='small'
        onClick={e => {
          e.stopPropagation();
          handleEditGroup(item.groupId);
        }}>
        <Edit />
      </IconButton>
    )
  );
};

const renderAddSubGroupButton = (
  item: any,
  isDeleting: any,
  handleAddGroup: any
) => {
  return (
    <IconButton
      disabled={!!isDeleting}
      size='small'
      onClick={e => {
        e.stopPropagation();
        handleAddGroup(item.groupId);
      }}>
      <GroupAdd />
    </IconButton>
  );
};

const GroupCounters = ({ group }: any) => {
  const chipStyle = {
    ml: 1,
    fontSize: '.75em',
  };
  const navigate = useNavigate();
  const groupsQueryString = qs.stringify(
    {
      groupIds: [...[group.groupId], ...group.childrenIds],
    },
    {
      arrayFormat: 'brackets',
      encode: false,
    }
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'right',
        flex: 1,
        mr: 2,
      }}>
      {group.userCount > 0 && (
        <Chip
          sx={chipStyle}
          label={`${group.userCount} ${t(
            `user${group.userCount > 1 ? 's' : ''}`
          )}`}
          size='small'
          variant='outlined'
          onClick={() => {
            navigate(`/settings/users?${groupsQueryString}`);
          }}
        />
      )}
      {group.assetCount > 0 && (
        <Chip
          sx={chipStyle}
          label={`${group.assetCount} ${t(
            `asset${group.assetCount > 1 ? 's' : ''}`
          )}`}
          size='small'
          variant='outlined'
          onClick={() => {
            navigate(`/settings/assets?${groupsQueryString}`);
          }}
        />
      )}
      {group.locationCount > 0 && (
        <Chip
          sx={chipStyle}
          label={`${group.locationCount} ${t(
            `location${group.locationCount > 1 ? 's' : ''}`
          )}`}
          size='small'
          variant='outlined'
          onClick={() => {
            navigate(`/settings/locations?${groupsQueryString}`);
          }}
        />
      )}
      {group.driverCount > 0 && (
        <Chip
          sx={chipStyle}
          label={`${group.driverCount} ${t(
            `driver${group.driverCount > 1 ? 's' : ''}`
          )}`}
          size='small'
          variant='outlined'
          onClick={() => {
            navigate(`/settings/drivers?${groupsQueryString}`);
          }}
        />
      )}
      {group.contactCount > 0 && (
        <Chip
          sx={chipStyle}
          label={`${group.contactCount} ${t(
            `contact${group.contactCount > 1 ? 's' : ''}`
          )}`}
          size='small'
          variant='outlined'
          onClick={() => {
            navigate(`/settings/contacts?${groupsQueryString}`);
          }}
        />
      )}
    </Box>
  );
};

const Groups = () => {
  const { t } = useTranslation();
  const [treeItems, setTreeItems] = useState<any>([]);
  const [groupsWithChildren, setGroupsWithChildren] = useState<Array<string>>(
    []
  );
  const {
    data: groups,
    isLoading,
    isFetching,
    isFetched,
    refetch,
    remove,
  } = useGroups() as any;
  const { data: userData } = useCurrentUser() as any;
  const {
    mutate: moveGroup,
    isLoading: isMoving,
    isSuccess: movedGroup,
  } = useMoveGroup();
  let rootFromParentId: Array<number> = [];
  const [rootGroupId, setRootGroupId] = useState<number>(0);
  const { openRightSidebar, closeRightSidebar, setFlashMessage } =
    useUIContext();
  const { reset, setActive, setAddGroupData } = useActionMenuContext();
  const [deleteGroup, setDeleteGroup] = useState<any>(null);
  const { mutate, error, isSuccess, isLoading: isDeleting } = useDeleteGroup();
  const { canWrite } = usePermissions();

  const isNodeAncestorOf = (node: any, target: any): boolean => {
    if (!target.targetItem || target.targetItem === 'root') return false;
    if (node.index === target.parentItem || node.index === target.targetItem) {
      return true;
    }

    const parent = Object.values(treeItems)?.filter((item: any) =>
      item.children.includes(target.targetItem)
    )[0] as any;

    if (node.index === parent.index) {
      return true;
    }

    return isNodeAncestorOf(node, {
      parentItem: parent.parentId ? `group_${parent.parentId}` : undefined,
      targetItem: parent.index,
    });
  };

  const addGroupWithChildren = (children: any, groupKey: string) => {
    if (
      children &&
      children.length > 0 &&
      !groupsWithChildren?.includes(groupKey)
    ) {
      groupsWithChildren.push(groupKey);
      /* @ts-ignore */
      setGroupsWithChildren(groupsWithChildren);
    }
  };

  useEffect(() => {
    if (movedGroup) {
      setFlashMessage({ message: t('success.move_group') });
    }
  }, [movedGroup]);

  const handleAddGroup = (parentGroupId: number | null = null) => {
    setActive(ActionMenuModuleTypes.AddGroup);
    openRightSidebar();
    setAddGroupData({
      rootGroupId: parentGroupId ?? rootGroupId,
    });
  };

  const handleEditGroup = (groupId: number) => {
    setActive(ActionMenuModuleTypes.EditGroup, { groupId });
    openRightSidebar();
  };

  const handleDeleteGroup = (group: any) => {
    setDeleteGroup({
      groupId: group.groupId,
      name: group.data,
    });
  };

  React.useEffect(() => {
    if (isSuccess) {
      setDeleteGroup(null);
      setFlashMessage({ message: t('success.deleted_group') });
    }
  }, [isSuccess]);

  useEffect(() => {
    // force render after query invalidation
    if (isFetching) {
      setTreeItems(null);
    }
  }, [isFetching]);

  const resetData = () => {
    remove();
    refetch();
  };

  useEffect(() => {
    if (!groups) return;
    rootFromParentId = [];
    let items: any = {};

    groups.map((group: any) => {
      const {
        groupId,
        parentId,
        name,
        assetCount,
        userCount,
        driverCount,
        contactCount,
        locationCount,
      } = group;
      const groupKey = `group_${groupId}`;
      const children = groups
        .filter((item: any) => item.parentId === groupId)
        .map((item: any) => `group_${item.groupId}`);
      const childrenIds = groups
        .filter((item: any) => item.parentId === groupId)
        .map((item: any) => item.groupId);
      /* @ts-ignore */
      items[groupKey] = {
        index: `group_${groupId}`,
        canMove: true,
        canRename: false,
        data: name,
        groupId,
        parentId,
        children,
        childrenIds,
        hasChildren: children && children.length > 0,
        assetCount,
        userCount,
        driverCount,
        contactCount,
        locationCount,
      };

      let rootId;
      if (!rootFromParentId[items.parentId]) {
        rootFromParentId[items.parentId] = group.rootId;
      } else {
        rootId = rootFromParentId[items.parentId];
      }
      items[groupKey].root = rootId;
      if (parentId === rootId) {
        if (items[`group_${parentId}`]) {
          items[`group_${parentId}`].children = [
            ...items[`group_${parentId}`].children,
          ];
        }
      }
      addGroupWithChildren(items[groupKey].children, groupKey);
    });

    if (Object.values(items).length > 0) {
      const rootItem: any = (() => {
        const root = Object.values(items).find(
          (el: any) => el.parentId === null
        );
        if (!root) {
          return Object.values(items).find(
            (el: any) => el.groupId === userData?.groupId
          );
        }
        return root;
      })();
      setRootGroupId(rootItem?.groupId);
      items.root = {
        canMove: false,
        canRename: false,
        data: 'root',
        groupId: null,
        hasChildren: true,
        index: 'root',
        parentId: null,
        children: [`group_${rootItem?.groupId}`],
      };
    }

    setTreeItems(items);
  }, [groups]);

  const classes = useStyles();

  return (
    <Grid container xs={11} spacing={0} sx={{ py: 0.5 }}>
      <Grid item xs={12}>
        <ConfirmDeleteDialog
          open={!!deleteGroup}
          onCancel={() => {
            setDeleteGroup(null);
          }}
          onConfirm={() => {
            if (deleteGroup) mutate({ groupId: deleteGroup?.groupId });
          }}
          title={t('Confirm to delete asset')}
          content={
            <>
              {t('Are you sure you want to delete')} <b>{deleteGroup?.name}</b>?
            </>
          }
        />
        {!isMoving &&
          isFetched &&
          !isLoading &&
          groups &&
          treeItems &&
          groups.length <= Object.values(treeItems).length && (
            <Container>
              {canWrite('GROUP') && (
                <div style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => handleAddGroup()}
                    color='secondary'
                    variant='outlined'
                    startIcon={<AddCircleOutline />}
                    sx={{ minWidth: 140 }}>
                    {t('add_group')}
                  </Button>
                </div>
              )}
              <hr />
              <UncontrolledTreeEnvironment
                canSearch={false}
                canRename={false}
                canDragAndDrop={true}
                canDropOnItemWithChildren={true}
                canDropOnItemWithoutChildren={true}
                canReorderItems={false}
                canDrag={items =>
                  items
                    .map((item: any) => {
                      return item.parentId !== null;
                    })
                    .reduce((a, b) => a && b, true)
                }
                canDropAt={(items, target) => {
                  const item = items[0] as any;
                  // don't allow dragging on any of item's children:
                  if (isNodeAncestorOf(item, target)) {
                    return false;
                  }
                  // don't allow dragging on current parent - cause no difference:
                  // @ts-ignore
                  if (`group_${item.parentId}` === target.targetItem) {
                    return false;
                  }
                  return true;
                }}
                onDrop={(items: TreeItem<any>[], target: DraggingPosition) => {
                  /* @ts-ignore */
                  const targetIndex = target.targetItem ?? target.parentItem;
                  /* @ts-ignore */
                  const targetName = treeItems[targetIndex].data;
                  const confirm = window.confirm(
                    `Confirm moving group "${items[0].data}" inside ${targetName}`
                  );
                  if (confirm) {
                    /* @ts-ignore */
                    moveGroup({
                      /* @ts-ignore */
                      groupId: items[0].groupId,
                      /* @ts-ignore */
                      index: targetIndex,
                    });
                  } else {
                    resetData();
                  }
                }}
                dataProvider={
                  new StaticTreeDataProvider(
                    Object.assign({}, treeItems) as any,
                    (item, data) => ({
                      ...item,
                      data,
                    })
                  )
                }
                getItemTitle={item => item.data}
                viewState={{
                  ...(groupsWithChildren &&
                    groupsWithChildren.length > 0 && {
                      ['tree-1']: {
                        expandedItems: groupsWithChildren,
                      },
                    }),
                }}
                renderItemTitle={({ title }) => <span>{title}</span>}
                renderItemArrow={({ item, context }: any) =>
                  item.hasChildren || item.isFolder ? (
                    <KeyboardArrowRightIcon
                      className={`${classes.rotateIcon} ${
                        context.isExpanded ? classes.downwardIcon : ''
                      }`}
                    />
                  ) : null
                }
                renderItem={({
                  item,
                  title,
                  arrow,
                  depth,
                  context,
                  children,
                }: any) => (
                  <li {...context.itemContainerWithChildrenProps}>
                    <div
                      {...context.itemContainerWithoutChildrenProps}
                      {...context.interactiveElementProps}
                      style={{
                        display: 'block',
                        userSelect: 'none',
                        padding: '.5rem 0',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          padding: '.5rem .8rem',
                          alignItems: 'center',
                          transition: 'all .3s ease',
                          backgroundColor: context.isDraggingOver
                            ? '#984d4f'
                            : '#36383a',
                          borderRadius: '.3rem',
                        }}>
                        {arrow}
                        {title}
                        <GroupCounters group={item} />
                        <ButtonGroup
                          sx={{ fontSize: '.65rem', ml: 'auto', mr: 0 }}>
                          {canWrite('GROUP') &&
                            renderAddSubGroupButton(
                              item,
                              isDeleting,
                              handleAddGroup
                            )}
                          {canWrite('GROUP') &&
                            renderEditButton(item, isDeleting, handleEditGroup)}
                          {canWrite('GROUP') &&
                            renderDeleteButton(
                              item,
                              isDeleting,
                              handleDeleteGroup,
                              t
                            )}
                        </ButtonGroup>
                      </div>
                    </div>
                    {children}
                  </li>
                )}
                renderTreeContainer={({ children, containerProps }) => (
                  <div
                    {...containerProps}
                    style={{ marginLeft: '0', position: 'relative' }}>
                    {children}
                  </div>
                )}
                renderItemsContainer={({ children, containerProps }: any) => (
                  <ul
                    {...containerProps}
                    style={{
                      listStyleType: 'none',
                      paddingLeft: containerProps.role ? '40px' : '0',
                    }}>
                    {children}
                  </ul>
                )}
                renderDragBetweenLine={({ draggingPosition, lineProps }) => (
                  <div
                    {...lineProps}
                    style={{
                      height: '2px',
                      width: '100%',
                      backgroundColor: '#FD6262',
                      marginLeft: `${draggingPosition.depth * 40}px`,
                    }}></div>
                )}>
                <Tree treeId='tree-1' rootItem='root' treeLabel='Groups' />
              </UncontrolledTreeEnvironment>
            </Container>
          )}
        {isLoading && (
          <Grid container spacing={0} sx={{ py: 0.5 }}>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant='text' sx={{ fontSize: '2rem' }} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Skeleton variant='text' sx={{ fontSize: '2rem' }} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Skeleton variant='text' sx={{ fontSize: '2rem' }} />
            </Grid>
          </Grid>
        )}
        {!isLoading && isFetched && groups.length <= 0 && <>{t('No groups')}</>}
      </Grid>
    </Grid>
  );
};

export default Groups;
